import type { UIQuickBuyPopUpSlideProps } from '@design-system/components/UI/UIQuickBuyPopUpSlide.props'
import { SIDEBAR_IDS } from '@design-system/data/sidebarIds'

const sidebarID = SIDEBAR_IDS.uiQuickBuyPopupSlide

type QuickBuyProps = Omit<UIQuickBuyPopUpSlideProps, 'id'>

type QuickBuyEvents = {
  saveChanges?: () => void
}

export const useUIQuickBuyPopUpSlide = () => {
  const { openDialog, closeDialog, isDialogOpen } = useDialog(sidebarID)

  const quickBuyProps = useState<QuickBuyProps>('quick-buy-props')
  const quickBuyEvents = useState<QuickBuyEvents | undefined>(
    'quick-buy-events'
  )

  const openQuickBuySlide = async (
    props: QuickBuyProps,
    events?: QuickBuyEvents
  ) => {
    quickBuyProps.value = props
    quickBuyEvents.value = events
    if (props.product) {
      await nextTick()
      openDialog(sidebarID)
    }
  }

  return {
    sidebarID,
    quickBuyProps,
    quickBuyEvents,
    openQuickBuySlide,
    openDialog,
    closeDialog,
    isQuickBuyOpen: isDialogOpen,
  }
}
